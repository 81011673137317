import { getD, getByIdD, postByIdD, getXTokenD } from "./baseDocument";

// 文献指南2.0 接口
// 获取我的文献列表
export function getdocumentCompass(data) {
  return getByIdD("/user/document", data);
}
// 获取制定者列表
export function getMaker() {
  return getD("/user/document/maker/pinyin");
}
// 获取疾病列表
export function getDisease() {
  return getD("/user/document/disease/pinyin");
}
// 获取年份
export function getDocumentYear() {
  return getD("/user/document/year");
}
// 已读
export function postRecord(data) {
  return postByIdD("/user/document/record", data);
}
// 收藏列表
export function getCollect(pageNo, pageSize) {
  return getXTokenD(
    `/user/document/mycollection?pageNo=${pageNo}&pageSize=${pageSize}`
  );
}
