
import {
  get,
  post,
  getById,
  getTool,
  getByIdF,
  postByIdF,
  putF,
  delF,
  getByIdX,
} from "./base";

/**
 * 文献指南接口
 * @returns {Promise<unknown | void>}
 */
// export function getdocumentCompass(data) {
//     return getById('/api/document',data)
// }
export function getdocumentCompass(data) {
    return getById('/v3/document',data)
}
//文献指南搜索
export function getSearchDocument(title) {
    return get('/api/admin/document',{"title":title})
}




/**
 * 根据id获取医学杂志内容
 * @param id 医学杂志的id
 * @returns {Promise<unknown | void>}
 */

export function getMedicalJournal(data) {
    return getById('/v3/magazineByCondition',data)
}

export function magazineYearGrouping(data) {
    return getById('/v3/magazineYearGrouping',data)
}

export function magazineNameGrouping(data) {
    return getById('/v3/magazineNameGrouping',data)
}
export function addressGrouping(data) {
    return getTool('/user/addressGrouping',data)
}
// export function getMedicalJournal(data) {
//     return getById('/api/magazine',data)
// }

//医学杂志搜索
export function getSearchMagazine(title) {
    return get('/api/admin/magazine',{"title":title})
}

/**
 * 根据id获取文献的pdf
 * @param id 文献的id
 * @returns {Promise<unknown | void>}
 */
export function getDocumentPdf(id) {
    return get('/api/document/'+id)
}


//社区

//1.社区访问总人数
export function getComVisitPensions(){
    return get('/api/CommunityVisitsTotalNumber')
}
//2.社区访问总人次
export function getConVisitCount(){
    return get('/api/CommunityVisitsTotalPersonTime')
}
//3.社区今日访问人数
export function getComDayVisitPension(){
    return get('/api/CommunityVisitsTodayNumber')
}



//社区内容

//1.内容访问总人数
export function getcontentVisitPensions(){
    return get('/api/CommunityVisitsTotalNumber_content')
}
//2.内容访问总人次
export function getcontentVisitCount(){
    return get('/api/CommunityVisitsTotalPersonTime_content')
}
//3.内容今日访问人数
export function getcontentDayVisitPension(){
    return get('/api/CommunityVisitsTodayNumber_content')
}

//top10
//3.内容访问人数前10
export function getContentVisitPenTop(){
    return get('/api/CommunityRecord_content_rank_10')
}
//

//3.内容访问人次前10
export function getContentVisitCountTop(){
    return get('/api/CommunityRecord_content_rank_10')
}

//上周发布内容访问人数排行榜
export function getWeekContentVisitTop(){
    return get('/api/CommunityRecord_content_lastWeek_number')
}



//社区每天访问人次
export function getCommunityPersons(){
    return get('/api/CommunityVisits')
}

//社区内容每天访问人次
export function getCommunityContentDay(){
    return get('/api/CommunityVisits_content')
}

//社区内容访问记录
export function getCommunityContent(){
    return get('/api/communityAccessRecord_content')
}

// 社区访问记录
export function getCommunityVisit(){
    return get('/api/communityAccessRecord')
}

/**
 * 向数据库中传type和member_id,content_id
 * @param type
 * @param member_id
 * @returns {Promise<unknown | void>}
 */
export function postCommunityView(type,member_id,content_id){
    return post('/api/AddCommunityView',{"type":type,"member_id":member_id,"content_id":content_id})
}



//社区(选择日期访问)访问人数（天）
//向数据库中传start_time和end_time
export function getComChangeVisitPension(start_time,end_time){
    return get('/api/CommunityVisitsTotalNumber_selectDate',{"start_time":start_time,"end_time":end_time})
}

//社区(选择日期访问)访问人次（天）
//向数据库中传start_time和end_time
export function getComChangeVisitCountDay(start_time,end_time){
    return get('/api/CommunityVisitsTotalPersonTime_day_selectDate',{"start_time":start_time,"end_time":end_time})
}


//社区(选择日期访问)访问人次
//向数据库中传start_time和end_time
export function getComChangeVisitCount(start_time,end_time){
    return get('/api/CommunityVisitsTotalPersonTime_hours_selectDate',{"start_time":start_time,"end_time":end_time})
}

//内容(选择日期访问)访问人数（天）:
export function getConChangeVisitPon(start_time,end_time){
    return get('/api/CommunityVisitsTotalNumber_selectDate_content',{"start_time":start_time,"end_time":end_time})
}

//内容(选择日期访问)访问人次（天）:
export function getConChangeVisitCountDay(start_time,end_time){
    return get('/api/CommunityVisitsTotalPersonTime_selectDate_content_day',{"start_time":start_time,"end_time":end_time})
}

//内容(选择日期访问)访问人次:
export function getConChangeVisitCount(start_time,end_time){
    return get('/api/CommunityVisitsTotalPersonTime_selectDate_content',{"start_time":start_time,"end_time":end_time})
}

//内容访问人数（月）
export function getComWeekMonth(year){
    return get('/api/communityRecord_content_month_number',{"year":year})
}
//内容访问人次（月）
export function getneiMonthVisitCunts(year){
    return get('/api/communityRecord_content_month_renci',{"year":year})
}

//社区(每月)访问人次
export function getComMonthCount(year){
    return get('/api/communityVisitsTotalPersonTime_month',{"year":year})
}
//社区(每月)访问人数
export function getComMonthPens(year){
    return get('/api/communityVisitsTotalNumber_month',{"year":year})
}


//直播数据管理

//观看总时长
export function getWatchSumTime(id){
    return get('/api2/liveWatchTotal_time/' +id)
}

//观看总人数
export function getWatchSumPons(id){
    return get('/api2/liveWatchTotal_number/' +id)
}

//峰值人数
export function getMaxPons(id){
    return get('/api2/liveWatchTotal_peaksNumber/' +id)
}

//观看人数统计（省份）
export function watchPonAddPro(id,limit){
    return get('/api2/liveWatch_People_Statistics_province/' +id, {"limit": limit})
}
//观看人数统计 图（省份）
export function watchPonAddProTu(id){
    return get('/api2/liveWatch_People_Statistics_province/' +id)
}

//观看人数统计（医院）
export function watchPonAddDro(id,limit){
    return get('/api2/liveWatch_People_Statistics_hospital/' +id, {"limit": limit})
}
//观看人数统计 图（医院）
export function watchPonAddDroTu(id,limit){
    return get('/api2/liveWatch_People_Statistics_hospital/' +id, {"limit":limit})
}

//观看人数统计（科室）
export function watchPonAddKe(id,limit){
    return get('/api2/liveWatch_People_Statistics_offices/' +id, {"limit": limit})
}
//观看人数统计 图（科室）
export function watchPonAddKeTu(id){
    return get('/api2/liveWatch_People_Statistics_offices/' +id)
}

//观看人数统计（职称）
export function watchPonAddZhi(id,limit){
    return get('/api2/liveWatch_People_Statistics_title/' +id, {"limit": limit})
}
//观看人数统计 图（职称）
export function watchPonAddZhiTu(id){
    return get('/api2/liveWatch_People_Statistics_title/' +id)
}

//观看人数统计（身份）
export function watchPonAddShen(id,limit){
    return get('/api2/liveWatch_People_Statistics_status/' +id, {"limit": limit})
}
//观看人数统计 图（身份）
export function watchPonAddShenTu(id){
    return get('/api2/liveWatch_People_Statistics_status/' +id)
}

//直播间查询开始时间小于现在的前20会议列表
export function getAskList(){
    return get('/api2/live_meet_limit20')
}


//会议接口
//会议查询开始时间小于现在的前20会议列表
export function getMeetList(){
    return get('/api2/plenary_session_meet_limit20')
}
//会议观看总人数
export function getMeetWatchSumPen(id){
    return get('/api2/plenary_watch_total_number/' + id)
}
//会议峰值人数
export function getMeetBigPen(id){
    return get('/api2/plenary_watch_total_top_number/' + id)
}
//会议观看总时长
export function getMeetWatchSumTime(id){
    return get('/api2/plenary_watch_total_duration/' + id)
}
//会议观看人数统计省份
export function getMeetWatchSumPro(id,limit){
    return get('/api2/plenary_watch_statistics_province/' + id,{"limit":limit})
}
//会议观看人数统计省份图
export function getMeetWatchSumProTu(id){
    return get('/api2/plenary_watch_statistics_province/' + id)
}


//会议观看人数统计医院
export function getMeetWatchSumDro(id,limit){
    return get('/api2/plenary_watch_statistics_hospital/' + id,{"limit":limit})
}
//会议观看人数统计医院图
export function getMeetWatchSumDroTu(id,limit){
    return get('/api2/plenary_watch_statistics_hospital/' + id,{"limit":limit})
}


//会议观看人数统计科室
export function getMeetWatchSumKe(id,limit){
    return get('/api2/plenary_watch_statistics_offices/' + id,{"limit":limit})
}

//会议观看人数统计科室图
export function getMeetWatchSumKeTu(id){
    return get('/api2/plenary_watch_statistics_offices/' + id)
}

//会议观看人数统计职称
export function getMeetWatchSumZhi(id,limit){
    return get('/api2/plenary_watch_statistics_title/' + id,{"limit":limit})
}
//会议观看人数统计职称图
export function getMeetWatchSumZhiTu(id){
    return get('/api2/plenary_watch_statistics_title/' + id)
}

//会议观看人数统计身份
export function getMeetWatchSumShen(id,limit){
    return get('/api2/plenary_watch_statistics_status/' + id,{"limit":limit})
}
//会议观看人数统计身份图
export function getMeetWatchSumShenTu(id){
    return get('/api2/plenary_watch_statistics_status/' + id)
}
//查询会议对应的直播间
export function getLookMeetWithSeed(id){
    return get('/api2/plenary_live_meet/' + id)
}
//注册总量
export function getRegisterSum(){
    return get('/api2/registerNumberTotal')
}
//今年注册量
export function getTodayYearRegister(){
    return get('/api2/registerNumberTotalToday')
}
//本月注册量
export function getTodayMonthRegister(){
    return get('/api2/registerNumberTotalThisMonth')
}
//今日注册量
export function getTodayRegister(){
    return get('/api2/registerNumberTotalThisYear')
}
//注册量（日）
export function getSurveyDay(start_time,end_time){
    return get('/api2/registerNumberTotalDay',{"start_time":start_time,"end_time":end_time})
}
//注册量（月）
export function getSurveyMonth(year){
    return get('/api2/registerNumberTotalMonth',{"year":year})
}
//注册量（年）
export function getSurveyYear(){
    return get('/api2/registerNumberTotalYear')
}
//注册量观看人数统计（省份）
export function getRegisterPro(limit){
    return get('/api2/registerNumberTotalProvince',{"limit":limit})
}
//注册量观看人数统计（省份）图
export function getRegisterProTu(){
    return get('/api2/registerNumberTotalProvince')
}
//注册量观看人数统计（医院）表格
export function getRegisterDro(limit){
    return get('/api2/registerNumberTotalHospital',{"limit":limit})
}
//注册量观看人数统计（医院）图
export function getRegisterDroTu(limit){
    return get('/api2/registerNumberTotalHospital',{"limit":limit})
}
//注册量观看人数统计（科室）表格
export function getRegisterKe(limit){
    return get('/api2/registerNumberTotalOffices',{"limit":limit})
}
//注册量观看人数统计（科室）图
export function getRegisterKeTu(limit){
    return get('/api2/registerNumberTotalOffices',{"limit":limit})
}
//注册量观看人数统计（职称）表格
export function getRegisterZhi(limit){
    return get('/api2/registerNumberTotalTitle',{"limit":limit})
}
//注册量观看人数统计（职称）图
export function getRegisterZhiTu(){
    return get('/api2/registerNumberTotalTitle')
}
//注册量观看人数统计（身份）表格
export function getRegisterShen(limit){
    return get('/api2/registerNumberTotalStatus',{"limit":limit})
}
//注册量观看人数统计（身份）图
export function getRegisterShenTu(){
    return get('/api2/registerNumberTotalStatus')
}
//招聘
export function getInvite(data){
    return getTool('/user/recruitment',data)
}
//招聘
export function getInviteContent(id){
    return getTool('/user/recruitment/' + id)
}


/**
 * 医学公式接口

 */
//首页-公式上新

export function getMedicalNews(){
    return getByIdF("/user/yun/h5/medicine/formula/five");
}

export function getMedicalHots(){
    return getByIdF("/user/yun/h5/medicine/formula/hot");
}

export function getMedicalDays(data){
    return getByIdF("/user/yun/h5/medicine/formula/three", data);
}

//查询用户患者
export function getFormulaPatientApi () {
    return getByIdF("/user/yun/h5/medicine/formula/patient");
}

//保存用户患者
export function postFormulaPatientApi (data) {
    return postByIdF('/user/yun/h5/medicine/formula/patient', data)
}

//编辑用户患者
export function putFormulaPatientApi (data) {
    return putF('/user/yun/h5/medicine/formula/patient', data)
}


//收藏医学公式
export function postFormulaCollectionApi (data) {
    return postByIdF('/user/yun/h5/medicine/formula/collection', data)
}

//取消收藏医学公式
export function delFormulaCollectionApi (data) {
    return delF('/user/yun/h5/medicine/formula/collection?contentId=' + data.contentId)
}
//获取收藏医学公式
export function getFormulaList (name) {
    return getByIdF('/user/yun/h5/medicine/formula/collect'+'?name='+name)
}


//新增公式纠错记录
export function postFormulaCollectionJCApi (data) {
    return postByIdF('/user/yun/h5/medicine/formula/error', data)
}
//获取医学公式分类
export function getMedicalType () {
    return getByIdF('/user/yun/h5/medicine/formula/classify')
}

// //通过code查询公式
// export function getMedicineFormulaApi (data) {
//     return getById('/v3/yun/h5/medicine/formula/' + data.type + '/' + data.code)
// }
//查看该用户是否收藏该公式
export function getFormulaCollection (data) {
    return getByIdF('/user/yun/h5/medicine/formula/collection', data)
}
//获取医学公式列表
export function getMedicalType_list (code) {
    return getByIdF('/user/yun/h5/medicine/formula/classify/formula'+'?code='+code)
}
//使用记录
export function getMedicalLog_time (data) {
    // return getByIdF('/user/yun/h5/medicine/formula/log/time'+'?name='+name)
    return getByIdX("/user/formula/log/group",data);
}

//使用记录-患者
export function getMedicalLog_patient(name) {
    return getByIdF('/user/yun/h5/medicine/formula/log/patient'+'?name='+name)
}
//使用记录-患者id
export function getMedicalLog_patientId(id) {
    return getByIdF('/user/yun/h5/medicine/formula/log/patient/id'+'?patientId='+id)
}

export function getMedicalLog_patientInfo(id) {
    return getByIdF('/user/yun/h5/medicine/formula/patient/id'+'?id='+id)
}

//删除使用记录——时间
export function delMedicalLog_time (id) {
    return delF('/user/yun/h5/medicine/formula/log'+'?id='+id)
}

//删除使用记录——患者
export function delMedicalLog_patient (id) {
    return delF('/user/yun/h5/medicine/formula/log/patient'+'?patientId='+id)
}

//搜索
export function getMedicalSearch(name,location) {
    return getByIdF('/user/yun/h5/medicine/formula',{'name':name,'location':location})
}
//搜索热门推荐
export function getMedicalRe() {
    return getByIdF('/user/yun/h5/medicine/formula/recommend')
}
//搜索历史记录
export function getMedicalSearch_history() {
    return getByIdF('/user/yun/h5/medicine/formula/search/history')
}
//纠错记录
export function getMedicalError_log() {
    return getByIdF('/user/yun/h5/medicine/formula/error')
}
//常用单位换算
export function getMedicalUntil() {
    return getByIdF('/user/yun/h5/medicine/formula/unit/conversion/three')
} 
//点击内容埋点
export function postDataBrowse (data) {
    return postByIdF('/user/app/vod/reportpageConten', data)
}
//获取推荐公式
export function getRecommend(pageNo, pageSize) {
         return getByIdF(`/user/formula/recommend/${pageNo}/${pageSize}`);
}
// 展开-获取公式结果
export function getGroupRecord(data) {
         return getByIdX("/user/formula/log/group/record",data);
}
