import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    memberId:'',
    ip:'',
    medicalMenu:0,
    medicalMenu_log:'left',
    medicalMenu_typeIndex:0,
    medicalMenu_typeListIndex:'A',
    editForm:{},
  },
  mutations: {
    memberId(state,data){
      state.memberId = data
    },
    ip(state,data){
      state.ip = data
    },
    medicalMenu(state, data) {
      state.medicalMenu = data;
    },
    medicalMenu_typeIndex(state, data) {
      state.medicalMenu_typeIndex = data;
    },
    medicalMenu_typeListIndex(state, data) {
      state.medicalMenu_typeListIndex = data;
    },
    medicalMenu_log(state, data) {
      state.medicalMenu_log = data;
    },
    editForm(state, data) {
      state.editForm = data;
    }
  },
  actions: {
    memberId(context,data){
      context.commit('memberId',data)
    },
    ip(context,data){
      context.commit('ip',data)
    },
    medicalMenu(context,data){
      context.commit('medicalMenu',data)
    },
    medicalMenu_typeIndex(context,data){
      context.commit('medicalMenu_typeIndex',data)
    },
    medicalMenu_typeListIndex(context,data){
      context.commit('medicalMenu_typeListIndex',data)
    },
    medicalMenu_log(context,data){
      context.commit('medicalMenu_log',data)
    },
    editForm(context,data){
      context.commit('editForm',data)
    },
  },
  modules: {
  }
})
