import axios from "axios";
const header = "https://application.yunicu.com"; //正式环境
// const header = "http://admin-dev-api.yunicu.com"; //测试环境
// const header= 'http://192.168.31.160:10086' // 韩笑本地
//现场环境
// const header='http://192.168.31.102:10086'

// const header='http://192.168.31.129:10086'
// const header='http://192.168.8.180:55555'
// const header='http://43.142.107.84'
// const header='http://192.168.31.160:10086'
// const header = 'http://34917c36t0.zicp.fun'
// const header = "http://192.168.5.57:33333";
import qs from "qs";

let admins;
export function setById(data) {
  admins = qs.parse(data);
}

export function getByIds(url, params) {
  return axios
    .get(header + url, {
      headers: {
        byId: params.byId,
      },
      params,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function getD(url, params) {
  return axios
    .get(header + url, {
      headers: {
        "x-token": admins.byId,
        type: 1,
      },
      params,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

//文献指南-招聘-杂志获取用户行为记录
export function getByIdD(url, params) {
  return axios
    .get(header + url, {
      headers: {
        //用户id
        byId: admins.byId,
        //用户端代码
        channel: admins.channel,
        os: admins.os,
        // "ip":admins.ip,
        version: admins.version,
      },
      params,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function postHeader(url, params) {
  return axios
    .post(header + url, params)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function postD(url, params) {
  return axios
    .post(header + url, params)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

export function postTokenD(url, params) {
  return axios
    .post(header + url, params, {
      headers: {
        byId: params.byId,
        channel: params.channel,
        os: params.os,
        // "ip":params.ip,
        version: params.version,
        contentId: params.contentId,
        location: params.location,
        type: params.type,
        "x-token": admins.byId, 
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

export function postToken(url, params) {
  return axios
    .post(header + url, params, {
      headers: {
        byId: params.byId,
        channel: params.channel,
        os: params.os,
        // "ip":params.ip,
        version: params.version,
        contentId: params.contentId,
        location: params.location,
        type: params.type,
        "x-token": params.byId, 
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

export function putD(url, params) {
  return axios
    .put(header + url, params)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

export function postByIdD(url, params) {
  return axios
    .post(header + url, params, {
      headers: {
        //用户id
        byId: admins.byId,
        //用户端代码
        channel: admins.channel,
        os: admins.os,
        // "ip":admins.ip,
        version: admins.version,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

export function delD(url, params) {
  return axios
    .delete(
      header + url,
      {
        headers: {
          //用户id
          byId: admins.byId,
          //用户端代码
          channel: admins.channel,
          os: admins.os,
          // "ip":admins.ip,
          version: admins.version,
          "x-token": admins.byId,
        },
      },
      params
    )
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

export function getXTokenD(url, params) {
  return axios
    .get(header + url, {
      headers: {
        "x-token": admins.byId,
        channel: admins.channel,
        os: admins.os,
      },
      params,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
